<template>
  <div>
    <el-input v-model="value">
      <template slot="append">
        <el-button @click="dialogVisible=true">选择视频</el-button>
      </template>
    </el-input>
    <div class="image-slot" style="width: 8rem;margin-top: .5rem">
      <i class="el-icon-video-camera"></i>
      <a v-if="value" :href="value | tomedia" target="_blank" class="y-desc">点击预览</a>
    </div>
    <el-dialog append-to-body title="选择视频" :show-close="false" :visible.sync="dialogVisible" width="50rem" @open="open" :before-close="handleClose">
      <el-upload
          style="position: absolute;right: 1.5rem;top: 1.5rem;"
          ref="uploader"
          :action="action"
          :headers="{ytoken:$store.state.sys.account.token}"
          name="file"
          :show-file-list="false"
          accept="video/mp4"
          :on-success="success"
          :on-error="error"
          :limit="1"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div class="flex-def flex-warp" style="min-height: 18rem">
        <div class="flex-def flex-zCenter flex-cCenter" v-for="(item,key) in list" :key="key" style="margin: .5rem;position: relative;width: calc(20% - 1rem);height: 8rem">
          <div class="flex-def flex-zCenter flex-cCenter" style="width: 8rem;position: relative">
            <div @click="choose(item)" class="y-pointer image-slot flex-def flex-zTopBottom" style="width: 8rem;height: 8rem">
              <i class="el-icon-video-camera" style="font-size: 4rem"></i>
            </div>
            <div class="y-pointer flex-def flex-zCenter flex-cCenter" style="color: #FFFFFF;background-color: rgba(0,0,0,.4);font-size: .8rem;line-height: 1.5rem;position: absolute;bottom: 0;width: 8rem">
              <div class="text-ellipsis" style="width: 95%">{{item.filename}}</div>
            </div>
            <div style="position: absolute;right: 0;top: 0;">
              <el-popconfirm @confirm="del(item)" title="确定删除吗？">
                <div slot="reference" class="flex-def flex-zCenter flex-cCenter" style="padding: .2rem;background-color: red;color: #FFFFFF">
                  <i class="el-icon-delete-solid"></i>
                </div>
              </el-popconfirm>
            </div>
<!--            <div style="position: absolute;left: 0;top: 0;">-->
<!--              <el-popconfirm @confirm="transcode(item)" title="确定将此视频转码吗？">-->
<!--                <div slot="reference" class="flex-def flex-zCenter flex-cCenter" style="padding: .2rem;background-color: #3e9afa;color: #FFFFFF">-->
<!--                  <i class="el-icon-magic-stick"></i>-->
<!--                </div>-->
<!--              </el-popconfirm>-->
<!--            </div>-->

          </div>
        </div>
        <div v-if="list.length === 0" class="flex-def flex-zCenter flex-cCenter" style="width: 100%;font-size: 1rem;color: #ededed;font-weight: 600">
          还没有资源...
        </div>
      </div>
      <template #footer>
        <el-pagination :hide-on-single-page="true" style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "y_upload_video",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    full:{
      type:Boolean,
      default: false
    },
    modelval: {
      type:String,
      default:""
    }
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data() {
    return {
      action:"",
      dialogVisible: false,
      value: this.modelval,
      page:1,
      list:[],
      total:0,
    }
  },
  mounted() {
    this.action = this.axios.defaults.baseURL  + this.$api.sys.attach.uploadAction;
  },
  methods:{
    open(){
      this.loadList();
    },
    // transcode(item){
    //   this.$api.sys.attach.transcode.video({id:item.id}).then(()=>{
    //     this.$message.success("转码任务启动成功");
    //   })
    // },
    del(item){
      this.$api.sys.attach.del({id:item.id}).then(()=>{
        this.loadList();
      })
    },
    pageChange(cur){
      this.page = cur;
      this.loadList();
    },
    loadList(){
      this.$api.sys.attach.page({page:this.page,type:3}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    success(){
      this.$refs.uploader.clearFiles();
      this.loadList();
    },
    error(e){
      console.log(e)
    },
    choose(video){
      this.dialogVisible=false;
      if(this.full){
        this.value = this.$store.state.sys.global.attachment_url + video.attachment
        return;
      }
      this.value = video.attachment;
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
</style>